import { checkAuth } from '../api';
import { UserDataType } from './dataTypes';

export interface AuthProvider {
  isAuthenticated: boolean;
  user: UserDataType | null;
  signin: (
    username: string,
    password: string,
    callback: (user: UserDataType | null) => void,
  ) => void;
  signout: (callback: VoidFunction) => void;
}

export const authProvider: AuthProvider = {
  isAuthenticated: false,
  user: null,
  signin(username, password, callback) {
    checkAuth(username, password).then(
      (response) => {
        authProvider.isAuthenticated = true;
        authProvider.user = { ...response, username, password };
        callback(authProvider.user);
      },
      () => {
        authProvider.isAuthenticated = false;
        callback(null);
      },
    );
  },
  signout(callback) {
    authProvider.isAuthenticated = false;
    authProvider.user = null;
    setTimeout(callback, 1);
  },
};
