import * as React from 'react';
import { SnackbarMessage } from '../utils/dataTypes';

export const SnackbarContext = React.createContext<
  (snackbarMessage: SnackbarMessage) => void
>(null!);

export const useSnackbar = function useSnackbar() {
  return React.useContext(SnackbarContext);
};
