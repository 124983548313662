import * as React from 'react';
import { authProvider } from '../utils/auth';
import { AuthContext } from '../hooks';
import { UserDataType } from '../utils/dataTypes';

export const AuthProvider = function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [user, setUser] = React.useState<UserDataType | null>(null);

  const signin = (
    email: string,
    password: string,
    callback: (user: UserDataType | null) => void,
  ) => {
    return authProvider.signin(email, password, (res) => {
      setUser(res);
      callback(res);
    });
  };

  const signout = (callback: VoidFunction) => {
    return authProvider.signout(() => {
      localStorage.removeItem('user');
      sessionStorage.removeItem('user');
      setUser(null);
      callback();
    });
  };

  const value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
