import * as React from 'react';
import { useLoader } from '../hooks';

export default function Loader(props: { visible: boolean }) {
  React.useLayoutEffect(() => {
    const { visible } = props;
    if (visible) {
      setLoaderVisibility(true);
    }
    return () => {
      if (visible) {
        setLoaderVisibility(false);
      }
    };
  }, [props.visible]);

  const [, setLoaderVisibility] = useLoader();
  return null;
}
