import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import AddPhotoAlternate from '@mui/icons-material/AddPhotoAlternate';
import Loader from './Loader';
import FileItem from './fileItem';
import { useSnackbar } from '../hooks';
import { FilesQuery, FileUpload } from '../api';
import { File, FileOfType } from '../utils/dataTypes';

const maxFilesToLoad = 30;

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function FileList({
  objectId,
  objectType,
  label = 'File list',
  tags,
  disabled = false,
}: {
  objectId?: string;
  objectType: FileOfType;
  label?: string;
  tags?: Record<string, string>;
  disabled?: boolean;
}) {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const queryClient = useQueryClient();
  const setSnackbarMessage = useSnackbar();

  const query: {
    linkedTo: string;
    fileOf: FileOfType;
    tags?: Record<string, string>;
  } = {
    linkedTo: objectId || '',
    fileOf: objectType,
    tags,
  };

  const {
    isLoading,
    isFetching,
    data: { items, total } = { items: [], total: 0 },
  } = useQuery({
    queryKey: ['files', 0, maxFilesToLoad, undefined, undefined, query],
    queryFn: FilesQuery,
    keepPreviousData: true,
    retry: false,
    enabled: !!(expanded && objectId),
    onError: (error: Error) => {
      setSnackbarMessage({
        severity: 'error',
        message: error instanceof Error ? error.message : JSON.stringify(error),
      });
    },
  });
  const files: File[] = items || [];

  const { mutate, isLoading: isUploading } = useMutation(FileUpload, {
    onSuccess: () => {
      setSnackbarMessage({
        severity: 'success',
        message: 'Uploaded',
      });
      queryClient.invalidateQueries(['files']);
    },
    onError: (error: Error) => {
      setSnackbarMessage({
        severity: 'error',
        message: error instanceof Error ? error.message : JSON.stringify(error),
      });
    },
  });

  const handleExpandedChange = () => {
    setExpanded((prev) => !prev && !!objectId);
  };

  const handleFileChange = (e: React.FormEvent<HTMLInputElement>) => {
    const selectedFile = e?.currentTarget?.files && e?.currentTarget?.files[0];
    if (!selectedFile || !objectId || !objectType) {
      return;
    }
    const fileDescription = prompt('Enter file description');
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('fileOf', objectType);
    formData.append('linkedTo', objectId);
    if (tags) {
      Object.entries(tags).forEach(([key, value]) => {
        formData.append(`tags[${key}]`, `${value}`);
      });
    }
    if (fileDescription) {
      formData.append('comment', fileDescription);
    }
    mutate(formData);
  };

  return (
    <Box sx={{}}>
      <Accordion
        expanded={expanded}
        onChange={handleExpandedChange}
        sx={{ backgroundColor: 'unset' }}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="files-content"
          id="files-header"
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography>{`${label}`}</Typography>
            <IconButton
              component="label"
              aria-label="add file"
              sx={{ padding: 0 }}
              disabled={disabled || !objectId}
            >
              <AddPhotoAlternate />
              <VisuallyHiddenInput type="file" onChange={handleFileChange} />
            </IconButton>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {!expanded ? null : (
            <Grid
              container
              spacing={{ xs: 2, md: 3, xl: 4 }}
              columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }}
            >
              {files.map((file) => (
                <Grid item xs={4} sm={4} md={5} lg={3} xl={2} key={file.id}>
                  <FileItem file={file} disabled={disabled || !objectId} />
                </Grid>
              ))}
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
      <Loader visible={expanded && (isFetching || isLoading)} />
    </Box>
  );
}
