import * as React from 'react';

import Modal from '@mui/material/Modal';
import Slide from '@mui/material/Slide';
import { grey } from '@mui/material/colors';
import Close from '@mui/icons-material/Close';

import { ModalMapContext } from '../hooks';
import { Loader } from '../components';
import { TruckForMap } from '../utils/dataTypes';
import { MapComponent } from '../App';

export const ModalMapProvider = function ModalMapProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [mapData, setMapData] = React.useState<TruckForMap[]>([]);
  const handleCloseMap = (/*event: object, reason: string*/) => {
    setMapData([]);
  };

  const handleSetMapData = (data: TruckForMap[]) =>
    setMapData(data.filter((truck) => truck.lastLocation));

  return (
    <ModalMapContext.Provider value={[mapData, handleSetMapData]}>
      {children}
      <Modal
        open={mapData.length > 0}
        onClose={handleCloseMap}
        closeAfterTransition
        aria-labelledby="modal-map"
        aria-describedby="modal-map"
        slotProps={{
          backdrop: {
            children: (
              <Close
                sx={{
                  position: 'absolute',
                  top: '4px',
                  right: '4px',
                  height: '24px',
                  width: '24px',
                  color: grey[100],
                }}
                onClick={handleCloseMap}
              />
            ),
          },
        }}
      >
        <Slide direction="up" in={mapData.length > 0}>
          <div
            style={{
              height: 'calc(100% - 40px)',
              width: 'calc(100% - 40px)',
              margin: '20px',
            }}
          >
            <React.Suspense fallback={<Loader visible />}>
              <MapComponent truckListData={mapData} />
            </React.Suspense>
          </div>
        </Slide>
      </Modal>
    </ModalMapContext.Provider>
  );
};
