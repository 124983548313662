import * as React from 'react';

import Modal from '@mui/material/Modal';
import Slide from '@mui/material/Slide';
import { grey } from '@mui/material/colors';
import Close from '@mui/icons-material/Close';

import { ModalImgContext } from '../hooks';

export const ModalImgProvider = function ModalImgProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [fileData, setFileData] = React.useState<string>('');
  const handleCloseImg = (/*event: object, reason: string*/) => {
    setFileData('');
  };

  const handleSetFileData = (data: string) => setFileData(data);

  return (
    <ModalImgContext.Provider value={[fileData, handleSetFileData]}>
      {children}
      <Modal
        open={!!fileData}
        onClose={handleCloseImg}
        closeAfterTransition
        aria-labelledby="modal-img"
        aria-describedby="modal-img"
        slotProps={{
          backdrop: {
            children: (
              <Close
                sx={{
                  position: 'absolute',
                  top: '4px',
                  right: '4px',
                  height: '24px',
                  width: '24px',
                  color: grey[100],
                }}
                onClick={handleCloseImg}
              />
            ),
          },
        }}
      >
        <Slide direction="up" in={!!fileData}>
          <img
            src={fileData}
            height="90%"
            style={{ margin: 'auto', display: 'block', marginTop: '2.5%' }}
            alt={'Error viewing image'}
          />
        </Slide>
      </Modal>
    </ModalImgContext.Provider>
  );
};
