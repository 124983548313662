import * as React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

import { LoaderContext } from '../hooks';

export const LoaderProvider = function LoaderProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isVisible, setIsVisible] = React.useState<number>(0);

  const handleShowLoader = (showLoader: boolean) => {
    if (!!showLoader) {
      setIsVisible((prev) => {
        return prev + 1;
      });
    } else {
      setIsVisible((prev) => {
        return prev > 0 ? prev - 1 : 0;
      });
    }
  };

  return (
    <LoaderContext.Provider value={[!!isVisible, handleShowLoader]}>
      {children}
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) =>
            Math.max(
              ...Object.values(
                theme.zIndex as unknown as Record<string, number>,
              ),
            ) + 1,
        }}
        open={!!isVisible}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </LoaderContext.Provider>
  );
};
