import React from 'react';
import get from 'lodash/get';
import { useFormik } from 'formik/dist/Formik';
import { useQuery } from '@tanstack/react-query';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon/SvgIcon';
import { debounce } from '@mui/material/utils';

import { Order, QueryFn, Result } from '../utils/dataTypes';
import { modifyedQueryFn, oneLevelObjectCompare } from '../utils/queryHelpers';

export default function SearchMultiFieldControl<
  TSearch extends { id: string },
>({
  fieldName,
  label,
  placeholder,
  getSelectedLabel,
  getItemLabel = getSelectedLabel,
  getItemSecondLabel,
  ListIcon,
  filterSelectedOptions = false,
  disabled,
  queryFn,
  startEmpty = true,
  orderby,
  order,
  additionalQueryParams,
  formik,
}: {
  readonly fieldName: string;
  readonly label: string;
  readonly placeholder?: string;
  readonly getSelectedLabel: (selectedItem: TSearch) => string;
  readonly getItemLabel: (item: TSearch) => string;
  readonly getItemSecondLabel?: (item: TSearch) => string;
  readonly ListIcon: typeof SvgIcon;
  readonly filterSelectedOptions?: boolean;
  readonly disabled?: boolean;
  readonly queryFn: QueryFn<any, TSearch>;
  readonly startEmpty?: boolean;
  readonly orderby: keyof TSearch;
  readonly order: Order;
  readonly additionalQueryParams?: Record<string, string>;
  readonly formik: ReturnType<typeof useFormik<any>>;
}) {
  const formikValue = get(formik.values, fieldName);
  const formikError = get(formik.errors, fieldName);
  const formikTouched = get(formik.touched, fieldName);

  const [value, setValue] = React.useState<TSearch[]>([]);
  const [inputValue, setInputValue] = React.useState('');
  const [searchQueryParams, setSearchQueryParams] = React.useState<
    Record<string, any>
  >({});
  const [options, setOptions] = React.useState<readonly TSearch[]>([]);

  const setSearch = React.useMemo(() => {
    return debounce((search: string): void => {
      setSearchQueryParams((prev) => {
        const newVal = { search, ...additionalQueryParams };
        if (oneLevelObjectCompare(prev, newVal)) {
          return prev;
        }
        return newVal;
      });
    }, 400);
  }, [additionalQueryParams]);

  const { data: { items } = { items: [], total: 0 } } = useQuery<
    Result<TSearch>,
    Error,
    Result<TSearch>,
    any // ToDo fix type
  >({
    queryFn: modifyedQueryFn(queryFn, startEmpty),
    queryKey: [fieldName, 0, 15, orderby, order, searchQueryParams],
    keepPreviousData: true,
    retry: false,
    enabled: !disabled,
  });

  React.useEffect(() => {
    if (formikValue.length) {
      const notSelectedItems = items.filter(
        (item) =>
          !formikValue.some(
            (selectedItem: any) => item.id === selectedItem?.id,
          ),
      );
      setOptions([...formikValue, ...notSelectedItems]);
    } else {
      if (inputValue === '') {
        setOptions([]);
      } else {
        setOptions([...items]);
      }
    }
  }, [formikValue, items]);

  React.useEffect(() => {
    setValue(formikValue || []);
  }, [formikValue]);

  React.useEffect(() => {
    setSearchQueryParams((prev) => {
      const newVal = { search: inputValue, ...additionalQueryParams };
      if (oneLevelObjectCompare(prev, newVal)) {
        return prev;
      }
      return newVal;
    });
  }, [additionalQueryParams]);

  const handleChange = (event: any, newValue: TSearch[] | null) => {
    if (!newValue) {
      setInputValue('');
    }
    formik.setFieldValue(fieldName, newValue, true);
  };

  const handleInputChange = (event: any, newInputValue: string) => {
    setInputValue(newInputValue);
    setSearch(newInputValue);
  };

  return (
    <Box key={fieldName}>
      <Autocomplete
        disabled={disabled}
        id={fieldName}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : getSelectedLabel(option)
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        multiple
        includeInputInList
        filterSelectedOptions={filterSelectedOptions}
        value={value}
        noOptionsText="No data"
        onChange={handleChange}
        onInputChange={handleInputChange}
        onClose={() => {
          queueMicrotask(() => formik.setFieldTouched(fieldName, true, true));
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            size="small"
            label={label}
            placeholder={placeholder}
            error={formikTouched && Boolean(formikError)}
            helperText={
              formikTouched &&
              formikError &&
              `${
                typeof formikError === 'string'
                  ? formikError
                  : 'Validation error'
              }`
            }
          />
        )}
        renderOption={(props, option) => (
          <li {...{ ...props, disabled }} key={(props as any).key || option.id}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <ListIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid
                item
                sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
              >
                <Box component="span" sx={{ fontWeight: 'regular' }}>
                  {getItemLabel(option)}
                </Box>
                {getItemSecondLabel && (
                  <Typography variant="body2" color="text.secondary">
                    {getItemSecondLabel(option)}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </li>
        )}
      />
    </Box>
  );
}
