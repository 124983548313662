import { Person, Query, QueryFn } from './dataTypes';

export const modifyedQueryFn = (
  queryFn: QueryFn,
  returnOnEmptyQuery: boolean,
): QueryFn => {
  if (returnOnEmptyQuery) {
    return async (query: Query<{ search: any }>) => {
      if (!query.queryKey[5] || query.queryKey[5].search == null) {
        return {
          items: [],
          limit: NaN,
          offset: NaN,
          total: NaN,
        };
      }
      return queryFn(query);
    };
  }
  return queryFn;
};

export const cleanObject = (
  input: Record<string, any> | undefined,
  keys: string[],
): Record<string, any> | undefined => {
  if (!input) return input;
  const result: Record<string, any> = {};
  Object.entries(input).forEach((entry) => {
    if (keys.includes(entry[0])) {
      result[entry[0]] = entry[1];
    }
  });
  return result;
};

export const oneLevelObjectCompare = (
  firstObj: Record<string, any>,
  secondObj: Record<string, any>,
): boolean => {
  const fristKeys = Object.keys(firstObj);
  const secondKeys = Object.keys(secondObj);
  if (fristKeys.length != secondKeys.length) {
    return false;
  }
  for (const key of fristKeys) {
    if (firstObj[key] !== secondObj[key]) {
      return false;
    }
  }
  return true;
};

export const getPath = (item: Person): string => {
  switch (item.type) {
    case 'Owner':
      return 'owners';
    case 'OwnerDriver':
      return 'ownersDrivers';
    case 'Coordinator':
      return 'coordinators';
    case 'CoordinatorDriver':
      return 'coordinatorsDrivers';
    case 'Driver':
      return 'drivers';
  }
};

export const getType = (item: Person): string => {
  switch (item.type) {
    case 'Owner':
      return '(O)';
    case 'OwnerDriver':
      return '(O/d)';
    case 'Coordinator':
      return '(C)';
    case 'CoordinatorDriver':
      return '(C/d)';
    case 'Driver':
      return '(D)';
  }
};
