import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { PaletteOptions } from '@mui/material/styles/createPalette';

const themeMode = localStorage.getItem('theme') === 'dark' ? 'dark' : 'light';
const textMode = localStorage.getItem('text') === 'bold' ? 'bold' : 'normal';

const lightOptions: PaletteOptions = {
  text: {
    primary: 'rgba(0,0,0,0.95)',
    secondary: 'rgba(0,0,0,0.75)',
    disabled: 'rgba(0,0,0,0.65)',
  },
  primary: {
    main: '#b53f41',
  },
  secondary: {
    main: '#f50057',
  },
  background: {
    default: 'gainsboro',
  },
};

const darkOptions: PaletteOptions = {
  primary: {
    main: '#b53f41',
  },
  secondary: {
    main: '#f50057',
  },
};

const defaultTheme = createTheme({
  typography: {
    fontWeightRegular: textMode === 'bold' ? 'bold' : 'initial',
  },
  palette: {
    mode: themeMode,
    ...(themeMode === 'dark' ? darkOptions : lightOptions),
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill:is(input)': {
            WebkitBoxShadow:
              themeMode === 'dark'
                ? '0 0 0 100px rgba(0,0,0,0) inset'
                : '0 0 0 100px rgba(ff,ff,ff,0) inset',
          },
        },
      },
    },
  },
});

const docElement = document.getElementById('doc') as HTMLElement;
docElement.classList.add(`${themeMode}-theme`);
const rootElement = document.getElementById('root') as HTMLElement;

const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={defaultTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </LocalizationProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
