import * as React from 'react';

import Slide from '@mui/material/Slide';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';

import { SnackbarContext } from '../hooks';
import { SnackbarMessage } from '../utils/dataTypes';

const AUTO_HIDE_DURATION = 6000;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const SnackbarProvider = function SnackbarProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [snackPack, setSnackPack] = React.useState<readonly SnackbarMessage[]>(
    [],
  );
  const [snackbarMessage, setSnackbarMessage] =
    React.useState<SnackbarMessage | null>(null);

  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (snackPack.length && !snackbarMessage) {
      setSnackbarMessage({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setSnackbarOpen(true);
    } else if (snackPack.length && snackbarMessage && snackbarOpen) {
      setSnackbarOpen(false);
    }
  }, [snackPack, snackbarMessage, snackbarOpen]);

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    snackbarMessage?.handleClose && snackbarMessage.handleClose(event, reason);
    setSnackbarOpen(false);
  };

  const handleExited = () => {
    setSnackbarMessage(null);
  };

  const handleAddMessage = (message: SnackbarMessage) => {
    setSnackPack((prev) => [...prev, message]);
  };

  return (
    <SnackbarContext.Provider value={handleAddMessage}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        TransitionComponent={Transition}
        onClose={handleSnackbarClose}
        autoHideDuration={AUTO_HIDE_DURATION}
        TransitionProps={{ onExited: handleExited }}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={handleSnackbarClose}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarMessage?.severity || 'error'}
          sx={{ width: '100%' }}
        >
          {snackbarMessage && snackbarMessage.message}
        </MuiAlert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
